/**
 * Component for displaying photo scenes
 * 
 * @param {*} scene 
 * @returns 
 */
const PhotosFrame = ({scene}) => (
    <div>
        {scene.content.map((path, index) => <img key={`img-${scene.id}-${index}`} src={path} />)}
    </div>
)

export default PhotosFrame;
