import PhotosFrame from "./frames/photos";

const Frame = ({scene}) => {
    let inner = null;
    console.log(scene);

    switch (scene.template) {
        case 'photos':
            console.log('photos!')
            inner = <PhotosFrame scene={scene} />
            break;
    }
    console.log(inner);

    return <>{inner}</>
}

export default Frame;
