import scenes from './scenes';
import Frame from './components/frame'

function App() {
  console.log(scenes)
  return (
    <main>
      <h1>seminomadic</h1>
      {scenes.map((s, i) => (
        <Frame key={`scene${i}`} scene={s} />
      ))}
    </main>
  );
}

export default App;
