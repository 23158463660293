// Super naive starter: Just export an array of scenes!
const scenes = [
    {
        id: 0,
        template: 'photos',
        content: ['/assets/scenes/0/IMG_8411.jpg', '/assets/scenes/0/IMG_8412.jpg', '/assets/scenes/0/unnamed.png'],
    },
    {
        id: 1,
        template: 'photos',
        content: ['/assets/scenes/1/FullSizeRender.jpg', '/assets/scenes/1/IMG_0116.jpg', '/assets/scenes/1/IMG_0116.jpg', '/assets/scenes/1/RenderedImage.jpg'],
    }
]

export default scenes;
